<template>
    <div class="delivery-address">
        <div class="add-address">
            <el-button type="primary" plain @click="addAddressBtn()">添加发货地址</el-button>
        </div>
        <el-table :data="deliveryAddressList" class="customTable" border style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="where_address" label="所在地址"></el-table-column>
            <el-table-column prop="detailed_address"  label="详细地址" align="center" min-width="200"></el-table-column>
            <el-table-column prop="postcode" label="邮编 " align="center">
                <template slot-scope="scope">
                      <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="ship_name"  label="发送人姓名" align="center"></el-table-column>
            <el-table-column prop="defualt" label="默认 " align="center">
                <template slot-scope="scope">
                      <span>{{scope.row.defualt===1?'是':'否'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row.id)">修改</el-link>
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无商品</span>
                    </div>
                </div>
            </template>
        </el-table>
    </div>
</template>
<script>
    import {transactionStuShipAddressList,transactionStuShipAddressDel,tranDistributionStuShipAddressList,tranDistributionStuShipAddressDel} from '@/utils/apis'
    export default {
        name: "DeliveryAddress",
        data(){
            return{
                exam_module_id:Number(this.$route.query.exam_module_id)||'',
                deliveryAddressList:[
                    {
                        where_address:'',
                        detailed_address:'',
                        postcode:'',
                        phone:'',
                        ship_name:'',
                        defualt:'',
                    }
                ],
            }
        },
        mounted() {
            this.getTransactionStuShipAddressList()
        },
        methods:{
            // 获取发货地址列表
            getTransactionStuShipAddressList(){
                let param ={}
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                if(this.exam_module_id===40){
                    transactionStuShipAddressList(param).then((res) => {
                        this.deliveryAddressList=res.data
                    })
                }else{
                    tranDistributionStuShipAddressList(param).then((res) => {
                        this.deliveryAddressList=res.data
                    })
                }
            },
            //添加发货地址
            addAddressBtn(){
                if (this.$route.query.op_id){
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/studentdeliveryaddress',
                        query:{
                            exam_module_id:this.exam_module_id,
                            op_id:this.$route.query.op_id,
                            course_id:this.$route.query.course_id
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/student/exam/onlineTradeManage/studentdeliveryaddress',
                        query:{
                            exam_module_id:this.exam_module_id
                        }
                    })
                }
            },
            //编辑
            editBtn(id){
                if (this.$route.query.op_id) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/studentdeliveryaddress',
                        query: {
                            op_id: this.$route.query.op_id,
                            r_id: id,
                            exam_module_id:this.exam_module_id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/onlineTradeManage/studentdeliveryaddress',
                        query: {
                             r_id:id,
                             exam_module_id:this.exam_module_id
                        }
                    })
                }
            },
            //删除
            delBtn(row){
                this.$confirm(`是否删除该数据，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id:row.id
                    }
                    if (this.$route.query.op_id) {
                        params.op_id = this.$route.query.op_id;
                    }
                    if (this.$route.query.course_id) {
                        params.course_id = this.$route.query.course_id;
                    }
                    if (this.$route.query.exam_module_id===40){
                        transactionStuShipAddressDel(params).then((res) => {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                duration:1000,
                                onClose: () => {
                                    this.getTransactionStuShipAddressList()
                                }
                            })
                        }).catch((err) => {
                            console.log('err', err)
                        })
                    }else {
                        tranDistributionStuShipAddressDel(params).then((res) => {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                duration:1000,
                                onClose: () => {
                                    this.getTransactionStuShipAddressList()
                                }
                            })
                        }).catch((err) => {
                            console.log('err', err)
                        })
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .delivery-address{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding:  0 20px;
        .add-address{
            margin-top: 20px;
        }
    }
</style>